
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportsQQBURcYVn4Meta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93lPBeiKWwTuMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editorO2oPBLU4lGMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/blog/editor.vue?macro=true";
import { default as indexfCNkoBqwNqMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/index.vue?macro=true";
import { default as moderationp10alQUBP2Meta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/moderation.vue?macro=true";
import { default as pendingBansq8In7NwiAqMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/pendingBans.vue?macro=true";
import { default as profilesqvM0NHhkmLMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/profiles.vue?macro=true";
import { default as expensesdjnriYRB1MMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexeX6lP89WNTMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewbQ3p8rtKwtMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingcMq5XRfmKtMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingXu36qm3uSmMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/translations/missing.vue?macro=true";
import { default as usersH8hqnOI5PfMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/admin/users.vue?macro=true";
import { default as apiXajyshrnMGMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/api.vue?macro=true";
import { default as _91slug_93x6eT9cebIwMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/blog/[slug].vue?macro=true";
import { default as index6DMF5YpMbIMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_932kJVPRYSpNMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93VjTFhLlXRhMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminAqrBqP9Hb2Meta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/census/admin.vue?macro=true";
import { default as indexG1qB7T2MwfMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/census/index.vue?macro=true";
import { default as contact8lWKxotlslMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/contact.vue?macro=true";
import { default as designl5Cxaoxh1RMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/design.vue?macro=true";
import { default as englishabhGeIoUJqMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/english.vue?macro=true";
import { default as faq1zdZyozEHmMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/faq.vue?macro=true";
import { default as inclusivenEZbdQ5SzwMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/inclusive.vue?macro=true";
import { default as index_45homeMOqC5Skc9oMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/index-home.vue?macro=true";
import { default as index_45localegFI1hVN9VEMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/index-locale.vue?macro=true";
import { default as langswitchLN57Xo7H4cMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/langswitch.vue?macro=true";
import { default as licenseH6sFzhb1X5Meta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/license.vue?macro=true";
import { default as academic0ftAyWjlFiMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/links/academic.vue?macro=true";
import { default as indexJfUusjSTbgMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/links/index.vue?macro=true";
import { default as media5fIzRvcB0PMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/links/media.vue?macro=true";
import { default as translinguisticscyIr1CJkdpMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/links/translinguistics.vue?macro=true";
import { default as zinexcwluxJyIbMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/links/zine.vue?macro=true";
import { default as names0qHQtpal5QMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/names.vue?macro=true";
import { default as indexx7RaEZwdroMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/nouns/index.vue?macro=true";
import { default as templatesBglONLrkImMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/nouns/templates.vue?macro=true";
import { default as peopleTSx8tyrjjoMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/people.vue?macro=true";
import { default as privacy6HZM8X7m4vMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/privacy.vue?macro=true";
import { default as _91username_938v1OYY5pFvMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_938D3Zi9JQcYMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/profile/card-[username].vue?macro=true";
import { default as editorjCCGrFNozGMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/profile/editor.vue?macro=true";
import { default as any0ZpYboOm6hMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/any.vue?macro=true";
import { default as askwdJnhLlJewMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/ask.vue?macro=true";
import { default as avoidingFGjB1N1rHlMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/avoiding.vue?macro=true";
import { default as indexW81anaoPRcMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/index.vue?macro=true";
import { default as mirrorHxwRXMw027Meta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/mirror.vue?macro=true";
import { default as pronounZ33n4mJMYKMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesGNoMXYQApLMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/sources.vue?macro=true";
import { default as teamdTKAk4ujyJMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/team.vue?macro=true";
import { default as terminologyyvYIZJLLZhMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/terminology.vue?macro=true";
import { default as termsNgeRYZVwLxMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/terms.vue?macro=true";
import { default as userLOd42oO1yDMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/user.vue?macro=true";
import { default as workshopsA5TDVJeooxMeta } from "/home/admin/www/pronomen.net/release/20250121223659/pages/workshops.vue?macro=true";
import { default as deklinationsmusterHoOdm86sCDMeta } from "~/data/nouns/deklinationsmuster.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsQQBURcYVn4Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansq8In7NwiAqMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiXajyshrnMGMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93x6eT9cebIwMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: index6DMF5YpMbIMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_932kJVPRYSpNMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93VjTFhLlXRhMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminAqrBqP9Hb2Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexG1qB7T2MwfMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact8lWKxotlslMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishabhGeIoUJqMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq1zdZyozEHmMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivenEZbdQ5SzwMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homeMOqC5Skc9oMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localegFI1hVN9VEMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchLN57Xo7H4cMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academic0ftAyWjlFiMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexJfUusjSTbgMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media5fIzRvcB0PMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticscyIr1CJkdpMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinexcwluxJyIbMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: names0qHQtpal5QMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexx7RaEZwdroMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesBglONLrkImMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleTSx8tyrjjoMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy6HZM8X7m4vMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_938v1OYY5pFvMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_938D3Zi9JQcYMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorjCCGrFNozGMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: any0ZpYboOm6hMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askwdJnhLlJewMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingFGjB1N1rHlMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexW81anaoPRcMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorHxwRXMw027Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounZ33n4mJMYKMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesGNoMXYQApLMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamdTKAk4ujyJMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyyvYIZJLLZhMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsNgeRYZVwLxMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userLOd42oO1yDMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsA5TDVJeooxMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20250121223659/pages/workshops.vue")
  },
  {
    name: "nouns-deklinationsmuster",
    path: "/deklinationsmuster",
    component: () => import("~/data/nouns/deklinationsmuster.vue")
  }
]